<template>
  <section class="success-page">
    <!-- Google tag (gtag.js) -->
    <script
      type="application/javascript"
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-341109664"
    ></script>
    <script type="application/javascript">
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-341109664");
    </script>

    <!-- Event snippet for Compra (1) conversion page -->
<script  type="application/javascript">
gtag('event', 'conversion', {
    'send_to': 'AW-341109664/Dl8mCIyep-AZEKDX06IB',
    'transaction_id': ''
});
</script>


    <span id="party-popper" class="mb-6">
      <vue-lottie :options="lottieOptions" :width="264" :height="246" />
    </span>
    <h4 class="title mb-5">Thank you for booking with us</h4>
    <h4 class="subtitle mt-1 mb-6">
      <p>We'll send an email to the email you provided with the ticket(s) shortly.</p>
    </h4>
  </section>
</template>

<script>
import VueLottie from "vue-lottie";
export default {
  components: {
    VueLottie,
  },
  data() {
    return {
      lottieOptions: {
        animationData: require("@/assets/animations/success.json"),
        loop: false,
        autoplay: true,
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
.success-page {
  text-align: center;
  margin-top: 12vh;
  margin-bottom: 20vh;
}

.title {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
}

.subtitle {
  font-weight: 400;
  color: #0e3d4d;
  font-size: 1.2rem;
}

.icon {
  font-size: 58px;
}
</style>
